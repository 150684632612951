* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: url("./assets/bg_1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

header {
  margin-top: 3em;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  padding: 1em;
  > div {
    display: flex;
    width: 33%;
    justify-content: center;
  }
  .logo {
    width: 250px;
    cursor: pointer;
  }
  button {
    background-color: #4a0350;
    color: white;
    border: 1px solid #edce74;
    border-radius: 4px;
    font-weight: 600;
    padding: 8px 12px;
    cursor: pointer;
  }
}

section.offers {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  display: none;
  img {
    width: 400px;
  }
  margin-bottom: 2em;
}

section.bundles {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  margin-top: 3em;
  flex-wrap: wrap;
  gap: 2em;
  margin-bottom: 4em;
  > div {
    transition: 0.12s;
    position: relative;
    max-width: 347px;
    cursor: pointer;
    opacity: 0.95;
    &:hover {
      transform: scale(1.1) rotate(1deg);
      opacity: 1;
    }
    > img {
      width: 100%;
    }
    > .price {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%);
      color: white;
      font-size: 18px;
      font-weight: 600;
      text-shadow: 0 0 6px #000;
    }
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #6109ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

select {
  padding: 8px 12px;
  background-color: #4a0350;
  color: white;
  border: 1px solid #edce74;
  border-radius: 4px;
  cursor: pointer;
}
